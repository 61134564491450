import React from 'react';
import { FormControl, FormControlProps } from './index';
import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  forwardRef,
  InputGroup,
  InputRightAddon,
  InputRightElement,
} from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import { PasswordInput, PasswordInputProps } from 'components/PasswordInput';

interface InputProps extends FormControlProps, ChakraInputProps {
  placeholder?: string;
  rightAddon?: React.ReactElement | string;
  rightElement?: React.ReactElement | string;
  isDisabled?: boolean;
  noSafariOpacityHack?: boolean;
}

export const Input = forwardRef<InputProps, 'input'>((inputProps, ref) => {
  const {
    formid,
    label,
    isOptional,
    errorMessage,
    helpText,
    introText,
    rightAddon,
    rightElement,
    isDisabled,
    ...props
  } = inputProps;

  return (
    <FormControl
      formid={formid}
      errorMessage={errorMessage}
      helpText={helpText}
      isOptional={isOptional}
      introText={introText}
      label={label}
      isDisabled={isDisabled}
    >
      <InputGroup alignItems="baseline">
        <ChakraInput
          m="2px"
          marginRight={rightAddon && '0px'}
          borderRightRadius={rightAddon && '0px'}
          id={formid}
          ref={ref}
          {...props}
        />
        {rightAddon && <InputRightAddon children={rightAddon} />}
        {rightElement && <InputRightElement children={rightElement} />}
      </InputGroup>
    </FormControl>
  );
});

export const PasswordInputFormControl = forwardRef<
  PasswordInputProps & InputProps,
  'div'
>(
  (
    {
      placeholder,
      label,
      isOptional,
      errorMessage,
      helpText,
      introText,
      rightAddon,
      isDisabled,
      ...props
    },
    ref,
  ) => {
    /*
  / We're explicitly passing an ID to the FormControl here, equal to the autoComplete prop, as per spec:
  / https://storybook.lvh.me/?path=/story/components-passwordinput--default
  / IMPORTANT: If we don't pass an explicit ID, Chakra's FormControl will generate its own ID for the input, which will break the spec.
  */
    placeholder =
      placeholder === undefined || placeholder === null
        ? t`Password`
        : placeholder;
    return (
      <FormControl
        ref={ref}
        formid={props.autoComplete}
        errorMessage={errorMessage}
        helpText={helpText}
        isOptional={isOptional}
        introText={introText}
        label={label}
        isDisabled={isDisabled}
      >
        <PasswordInput placeholder={placeholder} {...props} />
      </FormControl>
    );
  },
);
