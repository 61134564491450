import { t } from '@lingui/macro';
import { UploadFile } from '.';
import uniqueId from 'lodash/uniqueId';
import * as Sentry from '@sentry/react';

export function getFileUploadTokens(files: UploadFile[]): string[] {
  return files.map((f) => f.meta?.uploadToken || '').filter((t) => !!t);
}

export function isVirusError(file: UploadFile): boolean {
  return file.meta.statusCodeError === 418;
}

export function getUploadErrorMessage(file: UploadFile): string {
  if (isVirusError(file)) {
    return t`Virus detected`;
  }

  return t`Error uploading file`;
}

type DirType = 'FOLDER' | 'FILE';

export type UploadStatusType =
  | 'UNSTARTED'
  | 'UNSELECTED'
  | 'LOADING'
  | 'COMPLETED'
  | 'FAILED';

export type FolderType = {
  id: string;
  name: string;
  type: 'FOLDER';
  isLeaf: false;
  children: DirTreeType[];
  file?: never;
};

export type FileType = {
  id: string;
  name: string;
  type: 'FILE';
  isLeaf: true;
  file: File;
  children?: never;
};

export type DirTreeType = FolderType | FileType;

export function mapFilesToDirTree(files: File[]) {
  const root: FolderType = {
    id: 'ROOT',
    name: 'ROOT',
    type: 'FOLDER',
    isLeaf: false,
    children: [],
  };

  files.forEach((file) => {
    let parentNode = root;

    const path = file.webkitRelativePath.split('/');
    const [parentFolderNames, fileName] = [path.slice(0, -1), path.at(-1)];

    for (const name of parentFolderNames) {
      const subDir = parentNode.children.find(
        (dir) => dir.name === name && !dir.isLeaf,
      );

      // We find or create the folder, and assign it as the new parentNode
      if (subDir) {
        parentNode = subDir as FolderType;
      } else {
        const type: DirType = 'FOLDER';
        const newSubDir = {
          id: uniqueId(),
          name,
          type,
          isLeaf: false,
          children: [],
        } as FolderType;
        parentNode.children.push(newSubDir);
        parentNode = newSubDir;
      }
    }

    // Then we add the file to the found parentNode
    const type: DirType = 'FILE';
    parentNode.children.push({
      id: uniqueId(),
      name: fileName,
      type,
      isLeaf: true,
      file,
    } as FileType);
  });

  return root.children as DirTreeType[];
}

export function uploadErrorSentry(error: Error) {
  Sentry.withScope((s) => {
    s.setLevel('error');
    s.setTag('uploader', 'UploadError');

    Sentry.captureException(error);
  });
}
