import React, { PropsWithChildren } from 'react';
import {
  Button,
  FormControl as ChakraFormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  forwardRef,
  HStack,
  Text,
  VStack,
} from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import { List } from 'components/List';
import { GhostButton, PrimaryButton } from 'components/buttons';
import { NavigateBackIcon } from 'components/icons';
import { TextLink } from 'apps/TenantApp/components/TextLink';
export interface FormProps {
  onSubmit: (values: any) => void;
  CTASubmitName: string;
  CTACancelName?: string;
  CTAType?: string;
  isSubmitting?: boolean;
  withDivider?: boolean;
  onCancel?: () => void;
  cancelBackLink?: string;
  onCancelBack?: () => void;
  isDisabled?: boolean;
}

export const Form = forwardRef<PropsWithChildren<FormProps>, 'form'>(
  (
    {
      onSubmit,
      CTASubmitName,
      CTAType,
      CTACancelName,
      children,
      isSubmitting,
      withDivider,
      onCancel,
      cancelBackLink,
      onCancelBack,
      isDisabled,
      ...formProps
    },
    ref,
  ) => {
    return (
      <form ref={ref} onSubmit={onSubmit} {...formProps}>
        <List withDivider={withDivider}>{children}</List>
        <VStack spacing={4} width="100%">
          <PrimaryButton
            mt={6}
            width="100%"
            type="submit"
            isLoading={isSubmitting}
            isDisabled={!!isDisabled || isSubmitting}
          >
            {CTASubmitName}
          </PrimaryButton>
          {CTACancelName && onCancel && (
            <GhostButton onClick={onCancel} color="#718096" colorScheme="gray">
              {CTACancelName}
            </GhostButton>
          )}
          {cancelBackLink && (
            <Button variant="link" padding={4}>
              <TextLink to={cancelBackLink}>
                <NavigateBackIcon /> {t`Back`}
              </TextLink>
            </Button>
          )}
          {onCancelBack && (
            <Button variant="link" padding={4} onClick={onCancelBack}>
              <NavigateBackIcon /> {t`Back`}
            </Button>
          )}
        </VStack>
      </form>
    );
  },
);

export interface FormControlProps {
  formid?: string;
  errorMessage?: string;
  helpText?: string;
  label?: string;
  introText?: string;
  isOptional?: boolean;
  isDisabled?: boolean;
}

export const FormControl = forwardRef<FormControlProps, 'div'>(
  (
    {
      formid,
      errorMessage,
      helpText,
      children,
      label,
      introText,
      isOptional,
      isDisabled,
    },
    ref,
  ) => {
    return (
      <ChakraFormControl
        ref={ref}
        isDisabled={isDisabled}
        id={formid}
        isInvalid={!!errorMessage}
      >
        <HStack align="top" my="4px" spacing={0}>
          <FormLabel
            htmlFor={formid}
            fontSize="md"
            margin="2px"
            style={{ fontWeight: 'bold' }}
          >
            {label}
          </FormLabel>
          {isOptional && (
            <Text fontSize="md" color="#718096">
              ({t`optional`})
            </Text>
          )}
        </HStack>
        {introText && (
          <Text fontSize="md" color="#718096" marginBottom="2px">
            {introText}
          </Text>
        )}
        {children}
        {!errorMessage && helpText && (
          <FormHelperText fontSize="sm">{helpText}</FormHelperText>
        )}
        {errorMessage && (
          <FormErrorMessage size="sm">{errorMessage}</FormErrorMessage>
        )}
      </ChakraFormControl>
    );
  },
);
